
















































































































import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class serve extends Vue {
    public openQQ() {
        window.open("http://wpa.qq.com/msgrd?v=3&uin=3736172&site=qq&menu=yes", "_brank");
    }
    public metaInfo = {
        title: "会员服务-尊享会员-简单睡眠",
        meta: [
            {
                name: "简单睡眠会员，会员权益，福利活动，买一送一，优惠活动",
                content: "简单睡眠尊享会员权益，积分兑换，生日特权，专属活动。",
            },
        ],
    };
}
